<template>
  <div  v-if="event" class="col-lg-10 mx-auto mt-5 mb-5">
    <div class="row">
      <div class="col-4">
        <ul class="texte-center">
          <li data-title="ن">ن</li>
          <li data-title="ش">ش</li>
          <li data-title="ا">ا</li>
          <li data-title="ط">ط</li>
        
        </ul>
      </div>
    </div>
    <div class="col-lg-10 col-md-6 col-sm-6 mt-5 mx-auto">
      <div id="cardevent" class="card">
          <div class="row">
              <div class="col-lg-4 col-md-6 col-sm-6">
                  <img style="width: 100%;height: 100%" :src="`http://localhost:3000/${event.image}`">
              </div>
              <div class="col-lg-8 col-md-6 col-sm-6">
                  <div class="card-body pb-0">
                    <h5 class="texte-center">{{event.titre}}</h5>
                      <p class=" texte-right">{{event.description}}</p>
                      <i class="fa   float-right mt-3">  <span class="date">من</span>{{event.dateDebut}}<span class="date">الى</span>{{event.dateFin}}</i>
                  </div>
                  <router-link :to="{name: 'archiveEvent'}" class="btn  float-left  m-2"> قراءة المزيد </router-link>
              </div>
          </div>
      </div>
    </div> 
  </div>
</template>
<script setup>

import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
import eventsDataService from "@/services/eventsDataService";
import moment from 'moment'
  const event = ref(null)
  const store = useStore()
  eventsDataService.lastEvent()
    .then((res)=>{
      
      res.data.dateDebut = moment(res.data.dateDebut).format('YYYY-MM-DD')
      res.data.dateFin = moment(res.data.dateFin).format('YYYY-MM-DD')
      console.log(res.data)
      event.value = res.data
    })
  
    
</script>

<style scoped>

ul {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-165%, -50%) skew(0deg);
  display: flex;
  margin: 0;
  padding: 0; 
  border-radius: 5px; 
   
}
ul li {
  position: relative;
  list-style: none;
  width: 80px;
  height: 80px;
  background: white; 
  font-size: 3em;
  color: #00000044;
  line-height: 80px;
  box-sizing: border-box;
  overflow: hidden;
  margin: 0 -1px;
  text-align: center;
   font-family: 'Reem Kufi', sans-serif; 
}
ul li::before {
  content: attr(data-title);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color:  #45f3ff;
  animation: animate 2s linear infinite;
}
@keyframes animate {
  0% {
    top: -100%;
  }
  20% {
    top: 0%;
    background: transparent;
    color: #45f3ff;
  }
  40% {
    top: 0%;
    background: #074043;
    color: white;
  }
  60% {
    top: 0%;
    background: #074043;
    color: #45f3ff;
  }
  80% {
    top: -20%;
    background: transparent;
    color: #45f3ff;
  }
  100% {
    top: 100%;
  }
}
ul li:nth-child(1)::before {
  animation-delay: 0s;
}
ul li:nth-child(2)::before {
  animation-delay: 0.1s;
}
ul li:nth-child(3)::before {
  animation-delay: 0.2s;
}
ul li:nth-child(4)::before {
  animation-delay: 0.3s;
}
ul li:nth-child(5)::before {
  animation-delay: 0.4s;
}
ul li:nth-child(6)::before {
  animation-delay: 0.5s;
}
ul li:nth-child(7)::before {
  animation-delay: 0.6s;
}
ul li:nth-child(8)::before {
  animation-delay: 0.7s;
}
ul li:nth-child(9)::before {
  animation-delay: 0.8s;
}

#cardevent.card{

  box-shadow: 0 1px 1px rgb(0 0 0 / 11%), 0 2px 2px rgb(0 0 0 / 11%), 0 4px 4px rgb(0 0 0 / 11%), 0 8px 8px rgb(0 0 0 / 11%), 0 16px 16px rgb(0 0 0 / 11%), 0 32px 32px rgb(0 0 0 / 11%);
  border-radius: 0;
  border: none;
  background: #45f3ff; 
}

 

 
h5{
    font-size: 27px;
    text-align: center;
    margin-bottom: 20px;
    color: #074043;
    font-family: 'Reem Kufi', sans-serif; 
}

 

p{
    text-align: right;
    line-height: 35px;
    font-size: 20px;
    color: #074043;
    font-family: 'Harmattan', sans-serif; 
}
 

.fa{
  color: white;
  font-weight: bold;
  font-size: 20px;
  font-family: 'Reem Kufi', sans-serif;
}



.btn{
    background:#000;
    color: white;
    font-family: 'Reem Kufi', sans-serif;
    border-radius: 0;
    border: 2px solid white; 
    outline: none;
}
.btn:hover{
  color: #000;
  border: 2px solid #000;
  background: white;
}
.date{ 
  margin: 0 5px;
  font-weight: 300;
}


 
</style>