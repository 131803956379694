import http from "@/http-common"

class sousCatDataService{
    createSouCat(idCat, data){
        return http.post(`/categories/${idCat}/sous-categorie/`,  data)
    }

    updateSouCat(idCat, idSCat, data){
        return http.post('/categories/${idCat}', idCat, idSCat, data)
    }

    deleteSouCat(idCat, idSCat){
        return http.delete(`/categories/${idCat}/sous-categorie/${idSCat}`)
    }

    getAllSousCat(){
        return http.get('/categories/sous-categorie/')
    }

}

export default new sousCatDataService()