<template>
<div>
  <div  class="seven container mt-5 mb-5">  
    <h1>تصنيف ديوي العشري  </h1>
  </div>
  <p class="container adnenep text-center mt-3">يعرف تصنيف ديوي العشري على أنه تصنيف خاص بالمكتبات، وهو أكثرها شهرةً، وأصدر ملفل ديوي نظام ديوي العشري في عام 1876م، حيث يقوم مبدأ التصنيف على تقسيم العلوم الرئيسية إلى عشرة أقسام، ثم تقسيم كل قسم منها إلى عشرة أفرع، وهكذا على التوالي، ويعتبر هذا النظام من أسهل الأنظمة؛ لأنها تسهل عملية استخراج الكتاب المرغوب به من بين آلاف الكتب.</p>

  <div class="tab_container">
      <input id="tab1" type="radio" name="tabs" checked>
      <label for="tab1"> <span>000-099</span></label>

      <input id="tab2" type="radio" name="tabs">
      <label for="tab2"><span>100-199</span></label>

      <input id="tab3" type="radio" name="tabs">
      <label for="tab3"><span>200-299</span></label>

      <input id="tab4" type="radio" name="tabs">
      <label for="tab4"><span>300-399</span></label>

      <input id="tab5" type="radio" name="tabs">
      <label for="tab5"><span>400-499</span></label>

      <input id="tab6" type="radio" name="tabs">
      <label for="tab6"><span>500-599</span></label>

      <input id="tab7" type="radio" name="tabs">
      <label for="tab7"><span>600-699</span></label>

      <input id="tab8" type="radio" name="tabs">
      <label for="tab8"><span>700-799</span></label>

      <input id="tab9" type="radio" name="tabs">
      <label for="tab9"><span>800-899</span></label>

      <input id="tab10" type="radio" name="tabs">
      <label for="tab10"><span>900-999</span></label>


      <section id="content1" class="tab-content">
        <h3>المعارف العامة </h3>
            <p>تحوي هذه الفئة أجهزة الكمبيوتر والإنترنت والأجسام الغريبة والألغاز الغير مبررةٍ والتقويم والموسوعات والمكتبات والمتاحف والصحف وسجلات الأرقام القياسية العالمية.</p>
            
      </section>

      <section id="content2" class="tab-content">
        <h3>الفلسفة وعلم النفس  </h3>
            <p>الموت والفناء والأخلاق والعواطف والمشاعر والمنطق وتكوين الصداقات والخُدع البصرية والخرافات.</p>
          
      </section>

      <section id="content3" class="tab-content">
        <h3>الأديان وعلم الأساطير</h3>
            <p>الأميش (مجموعةٌ دينيةٌ في أمريكا الشمالية) وقصص الكتاب المقدس والبوذية (ديانة آسيوية) والمسيحية واليهودية والإسلام والكويكرز (أعضاءٌ في مجموعةٍ ذات جذورٍ مسيحيةٍ) والأديان العالمية الأخرى إضافةً إلى الأساطير اليونانية والرومانية وغيرها.</p>
        
      </section>

      <section id="content4" class="tab-content">
        <h3>العلوم الاجتماعية والتراث الشعبي</h3>
            <p>المهنة والعادات والبيئة والعائلات والحكايات الشعبية والسلطة والأخلاق والعملة.</p>
            
      </section>

      <section id="content5" class="tab-content">
        <h3>اللغات والقواعد</h3>
            <p>يصنف نظام ديوي العشري في هذه الفئة قواميس وقواعد اللغة الإنجليزية ولغة الإشارة والعربية والإسبانية ولغات أخرى من جميع أنحاء العالم.</p>
            
      </section>

      <section id="content6" class="tab-content">
        <h3>الرياضيات والعلوم</h3>
            <p>الحيوانات والأحياء والكيمياء والديناصورات والأسماك والجيولوجيا (علم طبقات الأرض) والحشرات والفيزياء والكواكب والنباتات والنجوم والطقس.</p>
            
      </section>

      <section id="content7" class="tab-content">
        <h3>الطب والتكنولوجيا</h3>
            <p>كتب الطبخ والهندسة والزراعة والصحة وجسم الإنسان والاختراعات والصناعات التحويلية والحيوانات الأليفة والتغذية والروبوت (إنسان آلي) وعلوم الفضاء.</p>
            
      </section>

      <section id="content8" class="tab-content">
        <h3>الفنون والترفيه</h3>
            <p>الهندسة المعمارية والصناعات اليدوية والرسم والألعاب والنكات والأفلام والموسيقى والدُمى وكتب الأغاني والرياضة.</p>
            
      </section>

      <section id="content9" class="tab-content">
        <h3>الأدب </h3>
            <p>أدب الأطفال ومجموعةُ مختاراتٍ أدبية والمسرحيات والشعر وشكسبير ونهج الكتابة.</p>
      </section>

      <section id="content10" class="tab-content">
        <h3>الجغرافيا والتاريخ </h3>
            <p>يصنف نظام ديوي العشري في هذه الفئة العالم القديم والسير الذاتية والبلدان والأمريكيين الأصليين والولايات والسفر والحروب.</p>
      </section>

  </div>   
</div>
</template>
<script>
export default {
    
}
</script>
<style scoped>
     
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/*Fun begins*/
.tab_container {
  width: 85%;
  margin: 0 auto;
  padding-top: 10px;
  position: relative;
  margin-bottom:100px;
   
}

input, section {
  clear: both;
  padding-top: 10px;
  display: none;
}

label {
  font-weight: 500;
  font-size: 18px;
  display: block;
  float: left;
  width: 10%;
  padding: 1.5em;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  background: #0D7377;
  font-family: 'Reem Kufi', sans-serif; 
}

#tab1:checked ~ #content1,
#tab2:checked ~ #content2,
#tab3:checked ~ #content3,
#tab4:checked ~ #content4,
#tab5:checked ~ #content5,
#tab6:checked ~ #content6,
#tab7:checked ~ #content7,
#tab8:checked ~ #content8,
#tab9:checked ~ #content9,
#tab10:checked ~ #content10 {
  display: block;
  padding: 10px;
  border: 4px solid #0D7377;
  color: #000;
  
  background-color: #f6f9fe;
}

.tab_container .tab-content p,
.tab_container .tab-content h3 {
  -webkit-animation: fadeInScale 0.7s ease-in-out;
  -moz-animation: fadeInScale 0.7s ease-in-out;
  animation: fadeInScale 0.7s ease-in-out;
    

}
.tab_container .tab-content p{
    font-size: 20px;
    text-align: center;
    font-family: 'Mirza', cursive;

}
.tab_container .tab-content h3  {
  text-align: center;
  font-weight: bold;
  font-family: 'Mirza', cursive;
 
 
  color: black;
}

.tab_container [id^="tab"]:checked + label {
  background: #fff;
  box-shadow: inset 1px 2px 0px 2px #0D7377;
  color: black;

}

/*Media query*/
@media only screen and (max-width: 930px) {
  label span {
    font-size: 14px;
    
  }
  
}

@media  (max-width: 768px) {
   

   label span {
    font-size: 14px;
   
    text-align: center;
  }
  label{
    width: 20%;
  }

   
}

/*Content Animation*/
@keyframes fadeInScale {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
 
.adnenef{
   
  font-family: 'Reem Kufi', sans-serif; 
  font-size: 40px;
} 
.adnenep{
   
  font-family: 'Harmattan', sans-serif;
  font-size: 20px;
  text-align: justify;
} 
</style>