<template>
    <!-- -------------------------------------------------counteur ------------------------------------------------- -->
     
   
    <div class="col-10 mx-auto" style=" margin-top:-102px " >   
        <div class="row dashboard-stats">
            <div class="col-md-3 col-sm-6 mt-5 mb-5 p-1">
                <section class="card panel-box">
                    <div class="panel-lefta panel-icon pt-5">
                        <i class="fa fa-home text-large stat-icon success-text"></i>
                        <p class="size-h1 no-margin countdown_first">147</p>
                        <p class="text-muted no-margin text"><span class="act">الموظفين </span></p>
                    </div>
                </section>
            </div>
            <div class="col-md-3 col-sm-6 mt-5 mb-5 p-1">
                <section class="card panel-box">
                    <div class="panel-leftb panel-icon  pt-5">
                        <i class="fa fa-shopping-cart text-large stat-icon danger-text"></i>
                        <p class="size-h1 no-margin countdown_second">92</p>
                        <p class="text-muted no-margin text"><span class="act">المنخرطين</span></p>
                    </div>
                </section>
            </div>
             <div class="col-md-3 col-sm-6 mt-5 mb-5 p-1">
                <section class="card panel-box">
                    <div class="panel-leftd panel-icon  pt-5">
                        <i class="fa fa-users text-large stat-icon info-text"></i>
                        <p class="size-h1 no-margin countdown_fourth">35450</p>
                        <p class="text-muted no-margin text"><span class="act">الكتب</span></p>
                    </div>
                </section>
            </div> 
            <div class="col-md-3 col-sm-6 mt-5 mb-5 p-1">
                <section class="card panel-box">
                    <div class="panel-leftc panel-icon  pt-5">
                        <i class="fa fa-rocket text-large stat-icon lovender-text"></i>
                        <p class="size-h1 no-margin countdown_third">3</p>
                        <p class="text-muted no-margin text"><span class="act" >الفضاءات</span></p>
                    </div>
                </section>
            </div>    
        </div> 
    </div>   
  
    
</template>
<script>

import livreDataService from '@/services/livreDataService'
export default {
    data(){
        return {
            
        }
    },
    created(){
        this.countUp(791);
        this.countUp2(555);
        this.countUp3(91);
        this.countUp4(999);
    },
    methods:{
        essai(){
            alert("data")
        },
        countUp4(count) {
            var div_by = 100,
                speed = Math.round(count / div_by),
                $display = $('.countdown_fourth'),
                run_count = 1,
                int_speed = 55;
            var int = setInterval(function () {
                if (run_count < div_by) {
                    $display.text(speed * run_count);
                    run_count++;
                } else if (parseInt($display.text()) < count) {
                    var curr_count = parseInt($display.text()) + 1;
                    $display.text(curr_count);
                } else {
                    clearInterval(int);
                }
            }, int_speed);
        },
        countUp3(count) {
            var div_by = 100,
                speed = Math.round(count / div_by),
                $display = $('.countdown_third'),
                run_count = 1,
                int_speed = 55;
            var int = setInterval(function () {
                if (run_count < div_by) {
                    $display.text(speed * run_count);
                    run_count++;
                } else if (parseInt($display.text()) < count) {
                    var curr_count = parseInt($display.text()) + 1;
                    $display.text(curr_count);
                } else {
                    clearInterval(int);
                }
            }, int_speed);
        },
        countUp2(count) {
            var div_by = 100,
                speed = Math.round(count / div_by),
                $display = $('.countdown_second'),
                run_count = 1,
                int_speed = 55;
            var int = setInterval(function () {
                if (run_count < div_by) {
                    $display.text(speed * run_count);
                    run_count++;
                } else if (parseInt($display.text()) < count) {
                    var curr_count = parseInt($display.text()) + 1;
                    $display.text(curr_count);
                } else {
                    clearInterval(int);
                }
            }, int_speed);
        },
        countUp(count) {
            var div_by = 100,
            speed = Math.round(count / div_by),
            $display = $('.countdown_first'),
            run_count = 1,
            int_speed = 55;
            var int = setInterval(function () {
            if (run_count < div_by) {
                $display.text(speed * run_count);
                run_count++;
            } else if (parseInt($display.text()) < count) {
                var curr_count = parseInt($display.text()) + 1;
                $display.text(curr_count);
            } else {
                clearInterval(int);
            }
            }, int_speed);
        }
    }
}
</script>
<style scoped>

.panel-box {
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100%;
    text-align: center;
    border: medium none;
    background-color: #fff;
    zoom: 90%;
    z-index: 5;
    
   
}
 
.panel-box .panel-icon {
    display: table-cell;
    padding: 29px;
    width: 1%;
    vertical-align: top;
    text-align: center;   
    
}
 
  
.panel-lefta{
    background-color: #9fd3c7;
    

} 
.panel-leftb{
    background-color: #385170;

} 
.panel-leftc{
   background-color: #385170;

} 
.panel-leftd{
    background-color: #9fd3c7;

}  

  .success-text {
    color: #1CB3C8;
}
  
 
  .danger-text {
    color: #DFE2E2;
}
  
 
  .lovender-text {
    color: #738598;
}
  
  .info-text {
    color: #FDB44B;
} 
  
  .size-h1 {
    font-size: 40px;
    color: #fff;
}

 
  .panel-icon p.text {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
} 
    .panel-icon i {
    text-align: center;
} 
    .panel-icon i {
    text-align: center;
} 
    .text-large {
    font-size: 50px;
}
    .text-large {
    font-size: 50px;
}
.act{
color: #000;
font-family: 'Mirza', cursive;
font-size: 27px;
}


/*=================================
  titres de sites + les paragraphes
==================================*/

.adnene{
   
  font-family: 'Reem Kufi', sans-serif; 
  font-size: 60px;
  text-shadow: 10px 10px 10px;
 } 
 
 .adnenef{
   
  font-family: 'Reem Kufi', sans-serif; 
  font-size: 40px;
 } 
 .adnenep{
   
  font-family: 'Harmattan', sans-serif;
  font-size: 20px;
  text-align: justify;
 } 
 

</style>

