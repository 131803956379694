import http from "@/http-common"


class userDataService {
    creerUser(data){
        return http.post('/auth/signUp', data)
    }

    envoyerEmail(data){
        return http.post('/auth/login/send',data)
    }

    login(data){
        console.log(data)
        return http.post('/auth/login', data, {
            /*headers: {
                'Authorization' : `Bearer $ {token}`
            }*/
        })
    }

    reserverLivre(data){
        return http.post('/reservations',data)
    }

    listeLivrereserve(idUser){
        var chaine = `/reservations/user/${idUser}`
        console.log(chaine)
        return http.get(chaine)
    }

    getUserById(id){
        return http.get(`/admin/user/${id}`)
    }

    getUserByNumber(number){
        return http.get(`/admin/emprunt/search/${number}`)
    }
}

export default new userDataService()