import http from "@/http-common"

class categorieDataService {
    createCat(data) {
        return http.post("/categories", data, {
            headers: {
                'Authorization' : `Bearer $ {token}`
            }
        })
    }

    listeToutCategorie() {
        return http.get("/categories", {
            headers: {
                'Authorization' : `Bearer $ {token}`
            }
        })
    }

    getCategorie(id){
        return http.get(`/categories/${id}`)
    }

    getAllSousCat(idcat){
        return http.get(`/categories/${idcat}/sous-categorie`)
    } 

    deleteCategorie(id){
        return http.delete(`/categories/${id}`)
    }
    /*getSousCat(idCat, idSCat){
        return http.get(`/categories/${idCat}/sous-categorie/${idSCat}`)
    }*/
}

export default new categorieDataService();