<template>
	<div>

	
 	<div  class="seven container mt-5 mb-5">  
      <h1>الكتب المختارة </h1>
  	</div>  
	<div id="ad" class="carousel_wrapper" >
  		<div id="dd" class="carousel">
		    <div id="a" class="slide one">
		       <div class="image-flip" ontouchstart="this.classList.toggle('hover');" style="height: 150px;width: 150px;">
		          	<div class="mainflip">
		              	<div class="frontside">
		                  	<div class="card">
	                          	<p><img class=" img-fluid" src="@/assets/img/index-livre/1-1.jpg" alt="card image"></p>
		                  	</div>
		              	</div>
		              	<div class="backside">
		                  	<div class="card" style="height: 150px;width: 150px;">
								<p><img class=" img-fluid" src="@/assets/img/index-livre/1-2.jpg" alt="card image"></p>
		                  	</div>
		              	</div>
		          	</div>
		      	</div>
		    </div>
		    <div id="a" class="slide two">
		      	<div class="image-flip" ontouchstart="this.classList.toggle('hover');" style="height: 150px;width: 150px;">
		          	<div class="mainflip">
		              	<div class="frontside">
		                  	<div class="card">
	                         	<p><img class=" img-fluid" src="@/assets/img/index-livre/2-1.jpg" alt="card image"></p>
		                  	</div>
		              	</div>
		              	<div class="backside">
		                  	<div class="card" style="height: 150px;width: 150px;">
								<p><img class=" img-fluid" src="@/assets/img/index-livre/2-2.jpg" alt="card image"></p>
		                  	</div>
		              	</div>
		          	</div>
		      	</div>
		    </div>
		    <div id="a" class="slide three">
		      <div class="image-flip" ontouchstart="this.classList.toggle('hover');" style="height: 150px;width: 150px;">
		          <div class="mainflip">
		              <div class="frontside">
		                  <div class="card">
                      			<p><img class=" img-fluid" src="@/assets/img/index-livre/3-1.jpg" alt="card image"></p>
		                  </div>
		              </div>
		              <div class="backside">
		                  	<div class="card" style="height: 150px;width: 150px;">
								<p><img class=" img-fluid" src="@/assets/img/index-livre/3-2.jpg" alt="card image"></p>
		                  	</div>
		              	</div>
		          	</div>
		      	</div>
		    </div>
		    <div id="a" class="slide four">
		      	<div class="image-flip" ontouchstart="this.classList.toggle('hover');" style="height: 150px;width: 150px;">
		          	<div class="mainflip">
		              	<div class="frontside">
		                  	<div class="card">
                          		<p><img class=" img-fluid" src="@/assets/img/index-livre/4-1.jpg" alt="card image"></p>
		                  	</div>
		              	</div>
		              	<div class="backside">
		                  	<div class="card" style="height: 150px;width: 150px;">
								<p><img class=" img-fluid" src="@/assets/img/index-livre/4-2.jpg" alt="card image"></p>
		                  	</div>
		              	</div>
		          	</div>
		      	</div>
		    </div>
		    <div id="a" class="slide five">
		      	<div class="image-flip" ontouchstart="this.classList.toggle('hover');" style="height: 150px;width: 150px;">
		          	<div class="mainflip">
		              	<div class="frontside">
		                  	<div class="card">
                          		<p><img class=" img-fluid" src="@/assets/img/index-livre/5-1.jpg" alt="card image"></p>
		                  	</div>
		              	</div>
		              	<div class="backside">
		                  	<div class="card" style="height: 150px;width: 150px;">
								<p><img class=" img-fluid" src="@/assets/img/index-livre/5-2.jpg" alt="card image"></p>
		                  	</div>
		              	</div>
		          	</div>
		      	</div>
		    </div>
		    <div id="a" class="slide six">
		      	<div class="image-flip" ontouchstart="this.classList.toggle('hover');" style="height: 150px;width: 150px;">
		          	<div class="mainflip">
		              	<div class="frontside">
		                  	<div class="card">
                          		<p><img class=" img-fluid" src="@/assets/img/index-livre/6-1.jpg" alt="card image"></p>
		                  	</div>
		              	</div>
		              	<div class="backside">
		                  	<div class="card" style="height: 150px;width: 150px;">
								<p><img class=" img-fluid" src="@/assets/img/index-livre/6-2.jpg" alt="card image"></p>
		                  	</div>
		              	</div>
		          	</div>
		      	</div>
		    </div>
		    <div id="a" class="slide seven">
		      	<div class="image-flip" ontouchstart="this.classList.toggle('hover');" style="height: 150px;width: 150px;">
		          	<div class="mainflip">
		              	<div class="frontside">
		                  	<div class="card">
	                          		<p><img class=" img-fluid" src="@/assets/img/index-livre/7-1.jpg" alt="card image"></p>
		                  	</div>
		              	</div>
		              	<div class="backside">
		                  	<div class="card" style="height: 150px;width: 150px;">
								<p><img class=" img-fluid" src="@/assets/img/index-livre/7-2.jpg" alt="card image"></p>
		                  	</div>
		              	</div>
		          	</div>
		      	</div>
		    </div>
		    <div id="a" class="slide eight">
		      	<div class="image-flip" ontouchstart="this.classList.toggle('hover');" style="height: 150px;width: 150px;">
		          	<div class="mainflip">
		              	<div class="frontside">
		                  	<div class="card">
	                          		<p><img class=" img-fluid" src="@/assets/img/index-livre/8-1.jpg" alt="card image"></p>
		                  	</div>
		              	</div>
		              	<div class="backside">
		                  	<div class="card" style="height: 150px;width: 150px;">
								<p><img class=" img-fluid" src="@/assets/img/index-livre/8-2.jpg" alt="card image"></p>
		                  	</div>
		              	</div>
		          	</div>
		      	</div>
		    </div>
		    <div id="a" class="slide nine">
		      	<div class="image-flip" ontouchstart="this.classList.toggle('hover');" style="height: 150px;width: 150px;">
		          	<div class="mainflip">
		              	<div class="frontside">
		                  	<div class="card">
		                      	<p><img class=" img-fluid" src="@/assets/img/index-livre/9-1.jpg" alt="card image"></p>
		                  	</div>
		              	</div>
		              	<div class="backside">
		                  	<div class="card" style="height: 150px;width: 150px;">
								<p><img class=" img-fluid" src="@/assets/img/index-livre/9-2.jpg" alt="card image"></p>
		                  	</div>
		              	</div>
		          	</div>
		      	</div>
		    </div>
		    <div id="a" class="slide tene">
		      	<div class="image-flip" ontouchstart="this.classList.toggle('hover');" style="height: 150px;width: 150px;">
		          	<div class="mainflip">
		              	<div class="frontside">
		                  	<div class="card">
                          		<p><img class=" img-fluid" src="@/assets/img/index-livre/10-1.jpg" alt="card image"></p>
		                  	</div>
		              	</div>
		              	<div class="backside">
		                  	<div class="card" style="height: 150px;width: 150px;">
								<p><img class=" img-fluid" src="@/assets/img/index-livre/10-2.jpg" alt="card image"></p>
		                  	</div>
		              	</div>
		          	</div>
		      	</div>
		    </div>
		    <div id="a" class="slide eleven">
		      	<div class="image-flip" ontouchstart="this.classList.toggle('hover');" style="height: 150px;width: 150px;">
		          	<div class="mainflip">
		              	<div class="frontside">
		                  	<div class="card">
                          		<p><img class=" img-fluid" src="@/assets/img/index-livre/11-1.jpg" alt="card image"></p>
		                  	</div>
		              	</div>
		              	<div class="backside">
		                  	<div class="card" style="height: 150px;width: 150px;">
								<p><img class=" img-fluid" src="@/assets/img/index-livre/11-2.jpg" alt="card image"></p>
		                  	</div>
		              	</div>
		          	</div>
		      	</div>
		    </div>
		    <div id="a" class="slide twelf">
		      	<div class="image-flip" ontouchstart="this.classList.toggle('hover');" style="height: 150px;width: 150px;">
		          	<div class="mainflip">
		              	<div class="frontside">
		                  	<div class="card">
                          		<p><img class=" img-fluid" src="@/assets/img/index-livre/12-1.jpg" alt="card image"></p>
		                  	</div>
		              	</div>
		              	<div class="backside">
		                  	<div class="card" style="height: 150px;width: 150px;">
								<p><img class=" img-fluid" src="@/assets/img/index-livre/12-2.jpg" alt="card image"></p>
		                  	</div>
		              	</div>
		          	</div>
		      	</div>
		    </div>
		    <div id="a" class="slide thirt">
		      	<div class="image-flip" ontouchstart="this.classList.toggle('hover');" style="height: 150px;width: 150px;">
		          	<div class="mainflip">
		              	<div class="frontside">
		                  	<div class="card">
                          		<p><img class=" img-fluid" src="@/assets/img/index-livre/13-1.jpg" alt="card image"></p>
		                  	</div>
		              	</div>
		              	<div class="backside">
		                  	<div class="card" style="height: 150px;width: 150px;">
								<p><img class=" img-fluid" src="@/assets/img/index-livre/13-2.jpg" alt="card image"></p>
		                  	</div>
		              	</div>
		          	</div>
		      	</div>
		    </div>
		    <div id="a" class="slide fort">
		      	<div class="image-flip" ontouchstart="this.classList.toggle('hover');" style="height: 150px;width: 150px;">
		          	<div class="mainflip">
		              	<div class="frontside">
		                  	<div class="card">
	                          	<p><img class=" img-fluid" src="@/assets/img/index-livre/14-1.jpg" alt="card image"></p>
		                  	</div>
		              	</div>
		              	<div class="backside">
		                  	<div class="card" style="height: 150px;width: 150px;">
								<p><img class=" img-fluid" src="@/assets/img/index-livre/14-2.jpg" alt="card image"></p>
		                  	</div>
		              	</div>
		          	</div>
		      	</div>
		    </div>
		    <div id="a" class="slide fivt">
		      	<div class="image-flip" ontouchstart="this.classList.toggle('hover');" style="height: 150px;width: 150px;">
		          	<div class="mainflip">
		              	<div class="frontside">
		                  	<div class="card">
	                          	<p><img class=" img-fluid" src="@/assets/img/index-livre/15-1.jpg" alt="card image"></p>
		                  	</div>
		              	</div>
		              	<div class="backside">
		                  	<div class="card" style="height: 150px;width: 150px;">
								<p><img class=" img-fluid" src="@/assets/img/index-livre/15-2.jpg" alt="card image"></p>
		                  	</div>
		              	</div>
		          	</div>
		      	</div>
		    </div>
  		</div>
	</div>	    
</div>
</template>
<style scoped>

.card {
    border: none;
    background: #fff;
    border-radius: 10px;
    height: 200px;
    width: 150px;
	
}

.image-flip:hover .backside,
.image-flip.hover .backside {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
    border-radius: .25rem;
    
}

.image-flip:hover .frontside,
.image-flip.hover .frontside {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.mainflip {
    -webkit-transition: 1s;
    -webkit-transform-style: preserve-3d;
    -ms-transition: 1s;
    -moz-transition: 1s;
    -moz-transform: perspective(1000px);
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transition: 1s;
    transform-style: preserve-3d;
    position: relative;
}

.frontside {
    position: relative;
    -webkit-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    z-index: 2;
    margin-bottom: 30px;
	
}

.backside {
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    -webkit-transform: rotateY( );
    -moz-transform: rotateY( );
    -o-transform: rotateY( );
    -ms-transform: rotateY( );
    transform: rotateY( );
	overflow: hidden;
   
}

.frontside,
.backside {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: 1s;
    -webkit-transform-style: preserve-3d;
    -moz-transition: 1s;
    -moz-transform-style: preserve-3d;
    -o-transition: 1s;
    -o-transform-style: preserve-3d;
    -ms-transition: 1s;
    -ms-transform-style: preserve-3d;
    transition: 1s;
    transform-style: preserve-3d;
	
}

.frontside .card,
.backside .card {
    min-height: 200px;
    min-width: 120px;
}

.backside .card a {
    font-size: 18px;
    color: #007b5e !important;
}

.frontside .card .card-title,
.backside .card .card-title {
    color: #0D7377 !important;
    font-size: 15px;
    
}
.frontside .card .card-text,
.backside .card .card-text{
    color: #000 !important;
	font-size: 12px;
 
}

.frontside .card .card-body img {
    width: 150px;
    height: 200px;   
}


*, *::before, *::after {
  	box-sizing: border-box;  
}

.carousel_wrapper {
  	position: relative;
  	width: 350px;
  	margin: 0px auto 0 auto;
	perspective: 1300px;
  	margin-bottom:450px;
	margin-top: 80px;
}
 @media  (max-width: 768px) {
   
 .carousel_wrapper {
	 display: none;
	}
.seven{
	display: none;
}
   
}

#dd {
  	position: relative;
  	width: 100%;
  	height: 100%;
  	transform-style: preserve-3d;
  	transform: rotateY(-360deg) translateZ(-412px);  
  	animation: swirl 50s steps(10000, end) infinite;  
}

#a {
  	position: absolute;
  	top: -5px;
  	left: 50px;
  	width:200px;
  	height: 187px;
}

#a img {
  	width: 150px;
  	height: 200px;	 
}


#a.one {
  	transform: rotateY(0deg) translateZ(412px);
}
#a.two {
  	transform: rotateY(24deg) translateZ(412px);
}
#a.three {
  	transform: rotateY(48deg) translateZ(412px);
}
#a.four {
  	transform: rotateY(72deg) translateZ(412px);
}
#a.five {
  	transform: rotateY(96deg) translateZ(412px);
}
#a.six {
  	transform: rotateY(120deg) translateZ(412px);
}
#a.seven {
  	transform: rotateY(144deg) translateZ(412px);
}
#a.eight {
  	transform: rotateY(168deg) translateZ(412px);
}
#a.nine {
  	transform: rotateY(192deg) translateZ(412px);
}
#a.tene {
  	transform: rotateY(216deg) translateZ(412px);
}
#a.eleven {
  	transform: rotateY(240deg) translateZ(412px);
}
#a.twelf {
  	transform: rotateY(264deg) translateZ(412px);
}
#a.thirt {
  	transform: rotateY(288deg) translateZ(412px);
}
#a.fort {
  	transform: rotateY(312deg) translateZ(412px);
}
#a.fivt {
  	transform: rotateY(336deg) translateZ(412px);
}
 
.adnene{
   
  font-family: 'Reem Kufi', sans-serif; 
  font-size: 60px;
  text-shadow: 10px 10px 10px;
 } 
 
 .adnenef{
   
  font-family: 'Reem Kufi', sans-serif; 
  font-size: 40px;
 } 
 .adnenep{
   
  font-family: 'Harmattan', sans-serif;
  font-size: 20px;
  text-align: justify;
  

 } 
 .adnenet{
  height: 20px;
  box-shadow:0 10px 10px -10px #0D7377 inset;
  border: 0;
  width: 50%
    }
/*
CSS3 ANIMATION
-------------------
 */

@keyframes swirl {   
  from {
    transform: rotateY(-360deg);
  }
  to {
    transform: rotateY(0deg);
  }
} 

</style>
<script>
export default {
	created(){
		 $(document).ready(function(){
        $("#ad").mouseleave(function(){
            $(".carousel").css("animation-play-state", "running");
        });
        $("#ad").mouseenter(function(){
            $(".carousel").css("animation-play-state", "paused");
        });
        
    });
	}
}
</script>