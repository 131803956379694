<template>
<div>
     <div  class="seven container mt-5 mb-5">  
        <h1>فضاءات المكتبة</h1>
    </div>
<section>
<div class="container mb-5">
	<div class="row">
		<div class="col-lg-4">
		  	<div class="img-container">
		    	<div class="img-inner">
		        		<img src="@/assets/img/cloun.png">
		    	</div>
		  	</div>
			<div class="text-container">
			    <h3>فضاء الاطفال </h3>
		      	<p class="mb-5">تخصيص فضاء للمطالعة والنشاطات المتنوعة يتكيف مع احتياجات الطفل بحيث يتم توفير أنشطة خاصة.</p>
		      	<router-link class="adnenep" :to="{name: 'Espacenfant'}">اقرءا المزيد</router-link>
			</div>
		</div>
	 	<div class="col-lg-4">
		  	<div class="img-container">
		    	<div class="img-inner">
		        		<img src="@/assets/img/informatique.png">
		    	</div>
		  	</div>
			<div class="text-container">
			    <h3>فضاء الاعلام الالي </h3>
		      	<p class="mb-5">تخصيص قاعة أنترنت والعديد من الحواسيب تماشيا مع متطلبات رواد المكتبة.</p>
				<router-link class="adnenep" :to="{name: 'EspaceInternet'}">اقرءا المزيد</router-link>
			</div>
		</div>
		<div class="col-lg-4">
		  	<div class="img-container">
		    	<div class="img-inner">
		        		<img src="@/assets/img/liv.png">
		    	</div>
		  	</div>
			<div class="text-container">
			     <h3>فضاء المطالعة </h3>
		      	<p class="mb-5">تخصيص قاعات مطالعة والعديد من الكتب والمعارض تلبية لاحتياجات القراء.</p>
				<router-link class="adnenep" :to="{name: 'EspaceLecture'}">اقرءا المزيد</router-link>
			</div>
		</div>
	</div>
</div>
</section>
</div>	 
</template>
<style scoped>
 

section {
  text-align: center;
}

.img-container {
    height: 220px;
    width: 250px;
    overflow: hidden;
    display: inline-block;
	 
}

.img-container img {
     
    height: 220px;
    
}

.text-container {
  	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
  	padding: 120px 20px 40px 20px;
  	border-radius: 10px;
  	background: rgb(250, 249, 249);
  	margin: -120px 0px 60px 0px;
  	line-height: 19px;
  	font-size: 14px;
}

.text-container h3 {
  	margin: 20px 0px 10px 0px;
  	color: #0D7377;
 	font-family: 'Mirza', cursive;  
	font-size: 35px;
	text-align: justify;
	text-align: center;
}
.text-container p {
  	margin: 20px 0px 10px 0px;
   	font-family: 'Harmattan', sans-serif;
 	font-size: 20px;
  	text-align: justify;
}

.text-container a {
	font-family: 'Harmattan', sans-serif;
  	font-size: 25px;
  	color: #14FFEC;
	float: left;
	 
}
 
 
</style>
 
