import http from "@/http-common"

class reservationDataService{
    getAllReservation(){
        return http.get('/reservations/')
    }

    annulerReservation(id){
        return http.delete(`/reservations/${id}`)
    }
}

export default new reservationDataService()