<template>
    <div>
        <heder v-if="!$route.meta.hideNavbar"></heder>
        <router-view />
        <foeter v-if="!$route.meta.hideNavbar"></foeter>
    </div>
</template>
<script>
import Heder from "@/components/heder"
import foeter from "@/components/foeter"
export default {
    components: {foeter, Heder}
}
</script>
<style lang="scss">



.link-active{
    border-bottom: 3px solid white;
    border-radius: 0;
}
</style>