import http from "@/http-common"

class adminDataService{
    ceerAdmin(data){
        console.log(data)
        return http.post('/admin/signUp', data)
    }

    envoyerEmail(data){
        return http.post('/admin/send',data)
    }

    login(data){
        console.log('wech nab3eth' ,data)
        return http.post('/admin/login', data, {
            /*headers: {
                'Authorization' : `Bearer ${token}`
            }*/
        })
    }

    getAllUsers(){
        return http.get('/admin/user/')
    }

    creerUser(data){
        return http.post(`/admin/user/`, data)
    }

    validerUser(idUser, data){
        return http.put(`/admin/user/${idUser}`, data)
    }
    
    getAllAdmins(){
        return http.get(`/admin/`)
    }

    validerAdmin(data){
        var id = {
            'id': data
        }
        return http.post(`/admin/valider/`,id)
    }

    supprimerAdmin(data){
        var id = {
            'id': data
        }
        return http.post(`/admin/desactiver/`,id)
    }
}

export default new adminDataService()