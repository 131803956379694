import http from "@/http-common"

class emprunteDataService{
    creerEmprunte(data){
        return http.post(`/admin/emprunt`, data)
    }

    listeTousEmprunt(){
        return http.get('/admin/emprunt/')
    }

    retourEmprunt(data){
        return http.post(`/admin/emprunt/retours`, data)
    }
}

export default new emprunteDataService()