<template>
    <div>
        <slideindex/>
        <cpmteurindex/>
        <events/>
        <espaceindex/>
        <livreindex/>
        <categorieindex/>
        <annexindex/>
    </div>
    
</template>
<script>

import livreindex from '@/components/livreindex'
import espaceindex from '@/components/espaceindex'
import cpmteurindex from '@/components/cpmteurindex'
import slideindex from '@/components/slideindex'
import annexindex from '@/components/annexindex'
import categorieindex from '@/components/categorieindex'
import events from '@/components/eventsIndex.vue'
export default {
    components: {slideindex, espaceindex, categorieindex, livreindex, cpmteurindex, annexindex, events  },
}

</script>
<style scoped>
body{
    background: red
}
</style>