<template>
<div>
     <div  class="seven container mt-5 mb-5">  
        <h1>ملحقات المكتبة</h1>
    </div>
   
<div class="container annex mt-5 mb-5">
 
    <div id="sa" class="items">
        <div class="entry p-3">
          <img class="img-fluid image-border" src="@/assets/img/annexe/1.jpg" >
          <div class="card-block ">
            <h3 class="adnenef card-title pr-2 mt-4" >ملحقة الحمامات  </h3>
            <p class="card-text adnenep pr-2">في موقع استراتيجي و محيط عمراني، تقع مكتبة المطالعة العمومية ملحقة الحامامات في حي البساتين بجوار ثانوية شريط لزهر بلدية الحكمامات، و هي مؤسسة عمومية تابعة إداريا للمكتبة الرئيسية للمطالعة العمومية لولاية تبسة، بدأت العمل فعليا في 13 مارس 2016 ، تقدم المكتبة خدمات لجميع شرائح المجتمع دون استثناء، تحوي أرصدة وثائقية متنوعة، تغطي معظم التخصصات. </p>
          </div>
          <router-link :to="{name: 'Annexe', params: {annexe: 'hammamet'}}" href=" #" title="Read more" class="read-more" >قراءة المزيد  </router-link>
        </div>
         <div class="entry p-3">
          <img class="img-fluid image-border" src="@/assets/img/annexe/2.jpg" height="300">
          <div class="card-block ">
            <h3 class="adnenef card-title pr-2 mt-4" >ملحقة تبسة  </h3>
            <p class="card-text adnenep pr-2">في موقع استراتيجي و محيط عمراني، تقع مكتبة المطالعة العمومية ملحقة تبسة في حي سكانسكا، قريبة من حي 200 سكن رفانة، مقابل استعجالات طب الأسنان بولاية  تبسة، و هي مؤسسة عمومية تابعة إداريا للمكتبة الرئيسية للمطالعة العمومية لولاية تبسة، بدأت العمل فعليا في 01 نوفمبر 2012 ، تقدم المكتبة خدمات لجميع شرائح المجتمع دون استثناء، تحوي أرصدة وثائقية متنوعة، تغطي معظم التخصصات. </p>
          </div>
          <router-link :to="{name: 'Annexe', params: {annexe: 'tebessa'}}" title="Read more" class="read-more" >قراءة المزيد  </router-link>
        </div>
         <div class="entry p-3">
          <img class="img-fluid image-border" src="@/assets/img/annexe/3.jpg" height="300">
          <div class="card-block ">
            <h3 class="adnenef card-title pr-2 mt-4" >ملحقة عين الزرقاء  </h3>
            <p class="card-text adnenep pr-2">في موقع استراتيجي و محيط عمراني، تقع مكتبة المطالعة العمومية ملحقة عين الزرقاء بجوار ثانوية عكريش عمار ببلدية عيم الزرقاء، و هي مؤسسة عمومية تابعة إداريا للمكتبة الرئيسية للمطالعة العمومية لولاية تبسة، بدأت العمل فعليا في 05 جانفي 2015 ، تقدم المكتبة خدمات لجميع شرائح المجتمع دون استثناء، تحوي أرصدة وثائقية متنوعة، تغطي معظم التخصصات. </p>
          </div>
          <router-link :to="{name: 'Annexe', params: {annexe: 'ain-zarga'}}" href=" #" title="Read more" class="read-more" >قراءة المزيد  </router-link>
        </div>
         <div class="entry p-3">
          <img class="img-fluid image-border" src="@/assets/img/annexe/4.jpg" height="300">
          <div class="card-block ">
            <h3 class="adnenef card-title pr-2 mt-4" >ملحقة ألعوينات  </h3>
            <p class="card-text adnenep pr-2">في موقع استراتيجي و محيط عمراني، تقع مكتبة المطالعة العمومية ملحقة العوينات في حي 20 أوت 1955 ، و هي مؤسسة عمومية تابعة إداريا للمكتبة الرئيسية للمطالعة العمومية لولاية تبسة، بدأت العمل فعليا في 2014 ، تقدم المكتبة خدمات لجميع شرائح المجتمع دون استثناء، تحوي أرصدة وثائقية متنوعة، تغطي معظم التخصصات. </p>
          </div>
          <router-link :to="{name: 'Annexe', params: {annexe: 'aouinat'}}" href=" #" title="Read more" class="read-more" >قراءة المزيد  </router-link>
        </div>
         <div class="entry p-3">
          <img class="img-fluid image-border" src="@/assets/img/annexe/5.jpg" height="300">
          <div class="card-block ">
            <h3 class="adnenef card-title pr-2 mt-4" >ملحقة ونزة  </h3>
            <p class="card-text adnenep pr-2">في موقع استراتيجي و محيط عمراني، تقع مكتبة المطالعة العمومية ملحقة ونزة في حي البياضة القديمة، و هي مؤسسة عمومية تابعة إداريا للمكتبة الرئيسية للمطالعة العمومية لولاية تبسة، بدأت العمل فعليا مارس 2006 ، تقدم المكتبة خدمات لجميع شرائح المجتمع دون استثناء، تحوي أرصدة وثائقية متنوعة، تغطي معظم التخصصات. </p>
          </div>
          <router-link :to="{name: 'Annexe', params: {annexe: 'ouanza'}}" href=" #" title="Read more" class="read-more" >قراءة المزيد  </router-link>
        </div>
        <div class="entry p-3">
          <img class="img-fluid image-border" src="@/assets/img/annexe/6.jpg" height="300">
          <div class="card-block ">
            <h3 class="adnenef card-title pr-2 mt-4" >ملحقة العقلة  </h3>
            <p class="card-text adnenep pr-2">في موقع استراتيجي و محيط عمراني، تقع مكتبة المطالعة العمومية ملحقة العقلة في حي الأمل، و هي مؤسسة عمومية تابعة إداريا للمكتبة الرئيسية للمطالعة العمومية لولاية تبسة، بدأت العمل فعليا في 05 جويلية 2014 ، تقدم المكتبة خدمات لجميع شرائح المجتمع دون استثناء، تحوي أرصدة وثائقية متنوعة، تغطي معظم التخصصات. </p>
          </div>
          <router-link :to="{name: 'Annexe', params: {annexe: 'ogla'}}" href=" #" title="Read more" class="read-more" >قراءة المزيد  </router-link>
        </div>
        <div class="entry p-3">
          <img class="img-fluid image-border" src="@/assets/img/annexe/7.jpg" height="300">
          <div class="card-block ">
            <h3 class="adnenef card-title pr-2 mt-4" >ملحقة مرسط  </h3>
            <p class="card-text adnenep pr-2">في موقع استراتيجي و محيط عمراني، تقع مكتبة المطالعة العمومية ملحقة مرسط في بلدية مرسط بمحاذات الطريق الوطني رقم 16 بجوار ثانوية زروقي عمار و متوسطة الشهيد عشي عبد المالك و نقر دائرة مرسط، و هي مؤسسة عمومية تابعة إداريا للمكتبة الرئيسية للمطالعة العمومية لولاية تبسة، بدأت العمل فعليا في 01 نوفمبر 2012 ، تقدم المكتبة خدمات لجميع شرائح المجتمع دون استثناء، تحوي أرصدة وثائقية متنوعة، تغطي معظم التخصصات. </p>
          </div>
          <router-link :to="{name: 'Annexe', params: {annexe: 'morsot'}}" href=" #" title="Read more" class="read-more" >قراءة المزيد  </router-link>
        </div>
        <div class="entry p-3">
          <img class="img-fluid image-border" src="@/assets/img/annexe/8.jpg" height="300">
          <div class="card-block ">
            <h3 class="adnenef card-title pr-2 mt-4" >ملحقة الكويف  </h3>
            <p class="card-text adnenep pr-2">في موقع استراتيجي و محيط عمراني، تقع مكتبة المطالعة العمومية ملحقة رأس العيون بجانب مكتب البريد و المواصلات بلدية راس العيون، و هي مؤسسة عمومية تابعة إداريا للمكتبة الرئيسية للمطالعة العمومية لولاية تبسة، بدأت العمل فعليا في 05 جويلية 2014 ، تقدم المكتبة خدمات لجميع شرائح المجتمع دون استثناء، تحوي أرصدة وثائقية متنوعة، تغطي معظم التخصصات. </p>
          </div>
          <router-link :to="{name: 'Annexe', params: {annexe: 'kouif'}}" href=" #" title="Read more" class="read-more" >قراءة المزيد  </router-link>
        </div>
        
         
    </div>
</div>
</div>
</template>
<script>
export default {
  created(){
    $(document).ready(function(){
        $("#sa").mouseleave(function(){
            $(".items").css("animation-play-state", "running");
        });
        $("#sa").mouseenter(function(){
            $(".items").css("animation-play-state", "paused");
        });
        
    });
  }
}
</script>
 
<style scoped>
/*==================
  el mol7a9at
====================*/
 
.entry:hover{
    
    opacity: 1;
    }
.entry .card-block{
   
    position: relative;
    color: #fff;
}
.read-more{
    color: #14FFEC !important;
    text-decoration:none;
    position: absolute;
    bottom: 15px;
    left: 15px;
    font-family: 'Mirza', cursive;
    font-size: 18px;

}
.image-border{
  border: 3px solid white;
  width: 100%;
  height: 200px;
} 
 
.container.annex {
    width: 100%;
    overflow: hidden;
    position: relative;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
     
}
 

.items {
    display: flex;
    align-items: center;
    width: fit-content;
    animation: carouselAnim 25s infinite alternate linear;
    
}

.entry {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 320px;
    height: 500px;
    overflow: hidden;
    background: #0D7377;
    margin: 1em;
    opacity: 0.7;
    -webkit-transition: .5s all ease;
    -moz-transition: .5s all ease;
    transition: .5s all ease;
     
}

@media only screen and (max-width: 768px) {
  .items {
    animation: carouselAnim 23s infinite alternate linear;
  }
  
@keyframes carouselAnim {
    from {
      transform: translate(0, 0);
    }
    to {
      transform: translate(calc(-100% + (1* 100px)));
    }
  }
}

@keyframes carouselAnim {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(calc(100% + (1 * 100px)));
  }
}
 
  
/*=================================
  titres de sites + les paragraphes
==================================*/

 
 .adnenef{
   
  font-family: 'Mirza', cursive;
  font-size: 27px;
  text-align: center;
  font-weight: bold;
 } 
 .adnenep{
  font-family: 'Mirza', cursive;
  font-size: 16px;
  text-align: justify;
  text-justify: inter-word;
 
 } 
</style>