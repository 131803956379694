<template>

    <div>
        <div v-on:dblclick="logAdmin" class="login-admin"></div>
        <div v-on:dblclick="signupAdmin" class="signup-admin"></div>
    <div class="container-fluid entete  pt-2">
        <div class="row">
            <div class="col-md-2 col-lg-2 col-sm-2 text-center mt-3  ">
                <img src="@/assets/img/Ministere.jpg" width="70" height="70" alt="logo" class="d-inline-block align-top">
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8   text-center mt-3 mb-3">
                <h2 class="h2-responsive text-center adnene">المكتبة الرئيسية للمطالعة العمومية - تبسة</h2>
                <h2 class="h2-responsive text-center adnene"  >اⴰⵙⴸⵍɛⵙⵝ ⵜⴰⴳⴻⵊⴷⴰⵍⵝ ⵏ ⵜⵖⵓⵔɛ ⵜ ⴰⵣⴰⵄⴻⵣⵃ</h2>
            </div>
            <div class="col-md-2 col-lg-2 col-sm-2 text-center mt-3  mb-3 ">
                <img  src="@/assets/img/bibtebessa.jpeg" width="70" height="70" alt="logo" class="d-inline-block align-top">
            </div>
        </div>
    </div>
     <header class="topbar">
      <div class="col-lg-8 mx-auto">
        <div class="row">
          <!-- social icon-->
          <div class="col-sm-12">
            <ul class="social-network">
                <li><a href="#"><img src="@/assets/logo/facebook.png"></a></li>
                <li><a href="#"><img src="@/assets/logo/twitter.png"></a></li>
                <li ><a href="#"><img src="@/assets/logo/youtube.png"></a></li>
                <li><a href="#"><img src="@/assets/logo/instagram.png"></a></li>
                <li><router-link v-if="!user" :to="{name: 'login', params: {type: 'user'}}" class="nav-link mx-5 mt-1" >  <i class="fa fa-sm fa-user-plus" aria-hidden="true"></i></router-link></li>
                <li><router-link v-if="!user" :to="{name: 'CreerCompte', params: {type: 'user'}}"  class="nav-link mt-1"  > <i class="fa fa-sm fa-sign-in" aria-hidden="true"></i><span class="sr-only">(current)</span></router-link></li>
                <li><a v-if="user" class="nav-link  mt-1" style="margin-left:85px;" >  {{user.nom}} ({{type}})</a></li>
                <li><a @click="logout" v-if="user" class="nav-link mt-1 pr-5"> خروج <i class="fa fa-sign-out" aria-hidden="true"></i></a></li>
            </ul>
          </div>

        </div>
      </div>
  </header>
    <nav class="navbar navbar-expand-lg navbar-light bg-dark  " id="main_navbar"  >
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav  adnenep">
                <li class="nav-item">
                    <router-link :to="{name: 'accueil'}" class="nav-link text-white" href="#">الصفحة الرئيسية <span class="sr-only">(current)</span></router-link>
                </li>
                <li v-if="type != 'admin'" class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false" style="color: #fff;">
                        تعريف
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><router-link :to="{name: 'mot'}" class="dropdown-item" href="#">كلمة المديرة </router-link></li>
                        <li><router-link :to="{name: 'Services'}" class="dropdown-item" href="#">تقرير شامل عن المكتبة </router-link></li>
                        <li><router-link :to="{name: 'etapbib'}" class="dropdown-item" href="#">مهام المكتبة </router-link></li>
                         <div class="dropdown-divider"></div>
                         <li><router-link :to="{name: 'CondBib'}" class="dropdown-item" href="#">شروط الانخراط في المكتبة</router-link></li>
                        <li><router-link :to="{name: 'CarteBib'}" class="dropdown-item" href="#">بطاقة فنية </router-link></li>
                    </ul>
                </li>
                <li v-if="type != 'admin'" class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false" style="color: #fff;">
                        الملحقات
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><router-link :to="{name: 'Annexe', params: {annexe: 'hammamet'}}" class="dropdown-item" href="#">مـلحـقـة الحمامات</router-link></li>
                        <li><router-link :to="{name: 'Annexe', params: {annexe: 'ogla'}}" class="dropdown-item" href="#"> مـلحـقـة العقلة </router-link></li>
                        <li><router-link :to="{name: 'Annexe', params: {annexe: 'aouinat'}}" class="dropdown-item" href="#">مـلحـقـة العوينات </router-link></li>
                        <li><router-link :to="{name: 'Annexe', params: {annexe: 'tebessa'}}" class="dropdown-item" href="#"> مـلحـقـة  تبسة </router-link></li>
                        <li><router-link :to="{name: 'Annexe', params: {annexe: 'kouif'}}" class="dropdown-item" href="#">  مـلحـقـة راس العيون   </router-link></li>
                        <li><router-link :to="{name: 'Annexe', params: {annexe: 'ain-zarga'}}" class="dropdown-item" href="#"> مـلحـقـة عين الزرقاء </router-link></li>
                        <li><router-link :to="{name: 'Annexe', params: {annexe: 'morsot'}}" class="dropdown-item" href="#"> مـلحـقـة مرسط </router-link></li>                        
                        <li><router-link :to="{name: 'Annexe', params: {annexe: 'ouanza'}}" class="dropdown-item" href="#"> مـلحـقـة ونزة </router-link></li>                        
                    </ul>
                </li>
                <li v-if="type != 'admin'" class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false" style="color: #fff;">
                        فضاء 
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><router-link :to="{name: 'Espacenfant'}" class="dropdown-item" href="#">الطفل</router-link></li>
                        <li><router-link :to="{name: 'EspaceInternet'}" class="dropdown-item" href="#">الانترنت</router-link></li>
                        <li><router-link :to="{name: 'EspaceLecture'}" class="dropdown-item" href="#">المطالعة</router-link></li>
                    </ul>
                </li>
               
                <li v-if="type != 'admin'" class="nav-item">
                    <router-link :to="{name: 'EmprunteBib'}" class="nav-link" href="#" style="color: #fff;">الاعارة </router-link>
                </li>
                <li v-if="type != 'admin'" class="nav-item">
                    <router-link :to="{name: 'Livre'}" class="nav-link" href="#" style="color: #fff;">قائمة الكتب </router-link>
                </li>
                <li v-if="type == 'user'" class="nav-item">
                    <router-link :to="{name: 'LivreUser'}" class="nav-link" href="#" style="color: #fff;">إعارة كتاب </router-link>
                </li>
                <li v-if="type == ''" class="nav-item">
                    <router-link :to="{name: 'InscriptionBib'}" class="nav-link" href="#" style="color: #fff;">انخرط معنا </router-link>
                </li>
                <li v-if="type != 'admin'" class="nav-item">
                    <router-link :to="{name: 'Contact'}" class="nav-link" href="#" style="color: #fff;">اتصل بنا </router-link>
                </li>
                <li v-if="type == ''" class="nav-item">
                    <router-link :to="{name: 'galerie'}" class="nav-link" href="#" style="color: #fff;">معرض الصور </router-link>
                </li> 
                <li v-if="type == 'admin' && (role == 3 || role == 0)" class="nav-item">
                    <router-link :to="{name: 'AjouterCat'}" class="nav-link" href="#" style="color: #fff;">التصنيفات  </router-link>
                </li>
                <li v-if="type == 'admin' && (role == 3 || role == 0)" class="nav-item">
                    <router-link :to="{name: 'AjouterSCat'}" class="nav-link" href="#" style="color: #fff;">التصنيفات الفرعية </router-link>
                </li> 
                <li v-if="type == 'admin' && (role == 3 || role == 0)" class="nav-item">
                    <router-link :to="{name: 'LivreAdmin'}" class="nav-link" href="#" style="color: #fff;"> قائمة الكتب </router-link>
                </li>
                <li v-if="type == 'admin' && (role == 1 || role == 0)" class="nav-item">
                    <router-link :to="{name: 'ReservationLivre'}" class="nav-link" href="#" style="color: #fff;">الحجز </router-link>
                </li> 
                <!--<li v-if="type == 'admin' && (role == 1 || role == 0)" class="nav-item">
                    <router-link :to="{name: 'EmprunteLivre'}" class="nav-link" href="#" style="color: #fff;">الإعارة </router-link>
                </li>-->
                <li class="nav-item">
                    <router-link :to="{name: 'archiveEvent'}" class="nav-link" href="#" style="color: #fff;">النشاطات</router-link>
                </li> 
                <li v-if="type == 'admin' && (role == 3 || role == 0)" class="nav-item">
                    <router-link :to="{name: 'events'}" class="nav-link" href="#" style="color: #fff;">انشاء نشاط </router-link>
                </li>
                <li v-if="type == 'admin' && (role == 2 || role == 0)" class="nav-item">
                    <router-link :to="{name: 'ValiderClient'}" class="nav-link" href="#" style="color: #fff;">المنخرطين </router-link>
                </li>
                <li v-if="type == 'admin' && role == 0" class="nav-item">
                    <router-link :to="{name: 'ValiderAdmin'}" class="nav-link" href="#" style="color: #fff;">المسيرين </router-link>
                </li> 
            </ul>
        </div>
                
        
    </nav>  
    </div>   
</template>
 
<script>
import VueJwtDecode from "vue-jwt-decode";
import cryptage from "@/cryptage";

    
    export default{
        data() {
            return {
                type: '',
                user: null, 
                role: null
            }
        }, 
        created() { 
            let token = localStorage.getItem("user");
            let type = localStorage.getItem("type");
            let role = localStorage.getItem("adminRole");
            if (token) {
                this.user = VueJwtDecode.decode(token)
                this.user.nom = cryptage.decrtpter(this.user.nom) || ''
                this.user.prenom = cryptage.decrtpter(this.user.prenom)
                this.type = type
                this.role = role
            }
        },
        methods: {
            logout() {
                localStorage.removeItem("user");
                localStorage.removeItem("type");
                localStorage.removeItem("adminRole");
                this.$router.go()
            },
            logAdmin(){
                if (this.user) {
                    localStorage.removeItem("user");
                    localStorage.removeItem("type");
                }
                this.$router.push(`/login/admin`);
            },
            signupAdmin(){
                if (this.user) {
                    localStorage.removeItem("user");
                    localStorage.removeItem("type");
                }
                this.$router.push({name: 'CreerCompte', params:{type: 'admin'}});
            }
        }
    }

 </script>
<style scoped>


.social-network img{
  width: 24px;
    
}
.topbar{
  background-color: #212529;
  padding: 0;
  height: 48px;
}
.topbar li .nav-link{
     color: #fff;
     position:absolute; 
     left:0;
     top:0px;
 }

.topbar .container .row {
  padding:0;
}

ul.social-network li {
    display:inline;
    margin: 5px 5px;
    border: 0px solid #2D2D2D;
    padding: 0px 0 0;
    width: 32px;
    display: inline-block;
    text-align: center;
    vertical-align: baseline;
    color: #000;
}

ul.social-network {
  list-style: none;
  margin: 5px 0 10px -25px;
  float: right;
  
}

.navbar{
    background:  #0D7377;;
    background: linear-gradient(60deg, #0D7377  30%, #000 30%); 
}
.navbar-toggler {
  
  background-color: #fff
} 
.h2-responsive{
    letter-spacing: 2px;
}
 
@media (min-width: 1040px) {
    #main_navbar{
    height: 8vh;
    }
    .container-fluid.entete{
    height: 14vh;
    } 
}
 
 
.dropdown-menu .dropdown-toggle::after {
    vertical-align: middle;
    border-right: 4px solid;
    border-bottom: 4px solid transparent;
    border-top: 4px solid transparent;
}
.dropdown-menu .dropdown .dropdown-menu {
    right: 100%;
    top: 0%;
    margin:0 10px;
    border-width: 0;
}
.dropdown-menu .dropdown .dropdown-menu.left {
    right: 100%;
    left: auto;
}
.dropdown-menu > li a:hover,
.dropdown-menu > li.show {
  background: #fff;
  color: black;
}
.dropdown-menu > li.show > a{
  color:black;
}

@media (max-width: 770px) {
    .dropdown-menu .dropdown .dropdown-menu {
        margin-top:0;
        border-width: 1px;
    }
    .nav-link{
        margin-top: 7px;
    }
    
    .nav-item.active {
 
   border-radius: 5px;
   color: white;
}
}
@media (min-width: 770px) {
    
    .nav-item.active {
   box-shadow: 0 0 3px   #fff ;  
   border-radius: 5px;
   color: white;
}
}

.dropdown-menu{
    background-color: #212121;
    border-radius: 5px;
    margin-top: 5px;
    right: 0%;
    text-align: right;
}
.dropdown-item{
    color: #14FFEC;
    text-align: right;
    right: 0%;
}

.nav-link:hover{
  box-shadow: 0 0 3px   #fff ;
  border-radius: 5px;
}
.nav-item {
    margin: 0 10px 0 10px;
    text-align: right;
    
}

.nav-item.active {
   
   border-radius: 5px;
   color: white;
}
 
.adnene{
   
  font-family: 'Reem Kufi', sans-serif; 
  font-size: 25px;
   } 
 
 
.adnenep{
   
    font-family: 'Mirza', cursive;  
    font-size: 19px;
    text-align: justify;  
 } 

.login-admin{
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    z-index: 100;
}
.signup-admin{
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    z-index: 100;
}
</style>