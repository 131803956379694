import http from "@/http-common"

class livreDataService {
    create(data) {
        return http.post(`/books`, data)
    }

    searchLivre(data){
        console.log(data)
        return http.post(`/books/search/`, data)
    }   

    listeToutLivre() {
        return http.get("/books")
    }

    listeLivreSouCat(idCat, idSCat){
        return http.get(`/books/${idCat}/${idSCat}`)
    }

    listeNouveauLivre(){
        return http.get('/books/getNew')
    }

    getLivreByTasnif(data){
        var tmp = {
            num : data
        }
        return http.post(`/books/num/`, tmp)
    }

    deleteLivre(data){
        return http.delete(`/books/${data}`)
    }

    updateLivre(data){
        var id = data.id
        var tmp = {
            Titlebook:  data.Titlebook,
            authorbook: data.authorbook
        }
        return http.put(`/books/${id}/`, tmp)
    }
}

export default new livreDataService();