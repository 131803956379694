import { createRouter, createWebHashHistory } from "vue-router";
import index from "@/pages/index"


function adminGuard(to){
    let token = localStorage.getItem("user");
    let type = localStorage.getItem("type");
    if(!token || type != 'admin'){
        return {
            path: '/login/admin',
            query: to.query,
            hash: to.hash
        }
    }
}

function userGuard(to){
    let token = localStorage.getItem("user");
    let type = localStorage.getItem("type");
    if(!token || type != 'user'){
        return {
            path: '/login/user',
            query: to.query,
            hash: to.hash
        }
    }
}



const routes = [{
        path: "/",
        name: "accueil",
        component: index,
    },
    {
        path: "/mot",
        name: "mot",
        component: () => import(/* webPackChunkName: "mot" */"@/pages/MotDirectrise")
    },
    {
        path: "/Services",
        name: "Services",
        component: () => import(/* webPackChunkName: "ServicesBib" */"@/pages/ServicesBib")
    },
    {
        path: "/CondBib",
        name: "CondBib",
        component: () => import(/* webPackChunkName: "CondBib" */"@/pages/CondBib")
    },
    {
        path: "/CarteBib",
        name: "CarteBib",
        component: () => import(/* webPackChunkName: "ServicesBib" */"@/pages/CarteBib"),
    },
    {
        path: "/Espacenfant",
        name: "Espacenfant",
        component: () => import(/* webPackChunkName: "Espacenfant" */"@/pages/Espacenfant")
    },
    {
        path: "/EspaceInternet",
        name: "EspaceInternet",
        component: () => import(/* webPackChunkName: "EspaceInternet" */"@/pages/EspaceInternet")
    },
    {
        path: "/EspaceLecture",
        name: "EspaceLecture",
        component: () => import(/* webPackChunkName: "EspaceLecture" */"@/pages/EspaceLecture")
    },
    {
        path: "/EmprunteBib",
        name: "EmprunteBib",
        component: () => import(/* webPackChunkName: "EmprunteBib" */"@/pages/EmprunteBib")
    },
    {
        path: "/galerie",
        name: "galerie",
        component: () => import(/* webPackChunkName: "galerie" */"@/pages/galerie")
    },
    {
        path: "/InscriptionBib",
        name: "InscriptionBib",
        component: () => import(/* webPackChunkName: "InscriptionBib" */"@/pages/InscriptionBib")
    },
    {
        path: "/login/:type",
        name: "login",
        component: () => import(/* webPackChunkName: "login" */"@/pages/login"),
        meta: {
            hideNavbar: true,
        }
    },
    {
        path: "/Livre",
        name: "Livre",
        component: () => import(/* webPackChunkName: "Livre" */"@/pages/livreVisiteur")
    },
    {
        path: "/AttenteInscription",
        name: "AttenteInscription",
        component: () => import(/* webPackChunkName: "AttenteInscription" */"@/pages/AttenteInscription")
    },
    {
        path: "/AttenteInscriptionAdmin",
        name: "AttenteInscriptionAdmin",
        component: () => import(/* webPackChunkName: "AttenteInscriptionAdmin" */"@/pages/AttenteInscriptionAdmin")
    },
    {
        path: "/CreerCompte/:type",
        name: "CreerCompte",
        component: () => import(/* webPackChunkName: "CreerCompte" */"@/pages/CreerCompte"),
        meta: {
            hideNavbar: true,
        }
    },
    {
        path: "/contact",
        name: "Contact",
        component: () => import(/* webPackChunkName: "contact" */"@/pages/contact")
    },
    {
        path: "/Annexe/:annexe",
        name: "Annexe",
        component: () => import(/* webPackChunkName: "Annexe" */"@/pages/Annexe")
    },
    {
        path: "/etapbib",
        name: "etapbib",
        component: () => import(/* webPackChunkName: "etapbib" */"@/pages/etapbib")
    },
    {
        path: "/AjouterCat",
        name: "AjouterCat",
        component: () => import(/* webPackChunkName: "AjouterCat" */"@/pages/AjouterCat"),
        beforeEnter(to, from){
            let token = localStorage.getItem("user");
            let type = localStorage.getItem("type");
            var role = localStorage.getItem('adminRole')
            if(!token || type != 'admin'){
                return {
                    path: '/login/admin',
                    query: to.query,
                    hash: to.hash
                }
            }else if (token && type == 'admin') {
                if(role != 0 && role !=  3){
                    return {name: "accueil"}
                }
            }
        }
    },
    {
        path: "/AjouterLivre",
        name: "AjouterLivre",
        component: () => import(/* webPackChunkName: "AjouterLivre" */"@/pages/AjouterLivre"),
        beforeEnter(to, from){
            let token = localStorage.getItem("user");
            let type = localStorage.getItem("type");
            var role = localStorage.getItem('adminRole')
            if(!token || type != 'admin'){
                return {
                    path: '/login/admin',
                    query: to.query,
                    hash: to.hash
                }
            }else if (token && type == 'admin') {
                if(role != 0 && role !=  3){
                    return {name: "accueil"}
                }
            }
        }
    },
    {
        path: "/AjouterSCat",
        name: "AjouterSCat",
        component: () => import(/* webPackChunkName: "AjouterSCat" */"@/pages/AjouterSCat"),
        beforeEnter(to, from){
            let token = localStorage.getItem("user");
            let type = localStorage.getItem("type");
            var role = localStorage.getItem('adminRole')
            if(!token || type != 'admin'){
                return {
                    path: '/login/admin',
                    query: to.query,
                    hash: to.hash
                }
            }else if (token && type == 'admin') {
                if(role != 0 && role !=  3){
                    return {name: "accueil"}
                }
            }
        }
    },
    /*{
        path: "/EmprunteLivre",
        name: "EmprunteLivre",
        component: () => import(  "@/pages/EmprunteLivre"),
        beforeEnter(to, from){
            let token = localStorage.getItem("user");
            let type = localStorage.getItem("type");
            var role = localStorage.getItem('adminRole')
            if(!token || type != 'admin'){
                return {
                    path: '/login/admin',
                    query: to.query,
                    hash: to.hash
                }
            }else if (token && type == 'admin') {
                if(role != 0 && role !=  1){
                    return {name: "accueil"}
                }
            }
        }
    },*/
    {
        path: "/ReservationLivre",
        name: "ReservationLivre",
        component: () => import(/* webPackChunkName: "ReservationLivre" */"@/pages/ReservationLivre"),
        beforeEnter(to, from){
            let token = localStorage.getItem("user");
            let type = localStorage.getItem("type");
            var role = localStorage.getItem('adminRole')
            if(!token || type != 'admin'){
                return {
                    path: '/login/admin',
                    query: to.query,
                    hash: to.hash
                }
            }else if (token && type == 'admin') {
                if(role != 0 && role !=  1){
                    return {name: "accueil"}
                }
            }
        }
    },
    {
        path: "/ValiderClient",
        name: "ValiderClient",
        component: () => import(/* webPackChunkName: "ValiderClient" */"@/pages/ValiderClient"),
        beforeEnter(to, from){
            let token = localStorage.getItem("user");
            let type = localStorage.getItem("type");
            var role = localStorage.getItem('adminRole')
            if(!token || type != 'admin'){
                return {
                    path: '/login/admin',
                    query: to.query,
                    hash: to.hash
                }
            }else if (token && type == 'admin') {
                if(role != 0 && role !=  2){
                    return {name: "accueil"}
                }
            }
        }
    },
    {
        path: "/LivreAdmin",
        name: "LivreAdmin",
        component: () => import(/* webPackChunkName: "LivreAdmin" */"@/pages/LivreAdmin"),
        beforeEnter(to, from){
            let token = localStorage.getItem("user");
            let type = localStorage.getItem("type");
            var role = localStorage.getItem('adminRole')
            if(!token || type != 'admin'){
                return {
                    path: '/login/admin',
                    query: to.query,
                    hash: to.hash
                }
            }else if (token && type == 'admin') {
                if(role != 0 && role !=  3){
                    return {name: "accueil"}
                }
            }
        }
    },
    {
        path: "/LivreUser",
        name: "LivreUser",
        component: () => import(/* webPackChunkName: "LivreUser" */"@/pages/LivreUser"),
        beforeEnter: [userGuard]
    },
    {
        path: "/Impression/:id",
        name: "Impression",
        component: () => import(/* webPackChunkName: "Impression" */"@/pages/Impression"),
        meta: {
            hideNavbar: true,
        },
        beforeEnter(to, from){
            let token = localStorage.getItem("user");
            let type = localStorage.getItem("type");
            var role = localStorage.getItem('adminRole')
            if(!token || type != 'admin'){
                return {
                    path: '/login/admin',
                    query: to.query,
                    hash: to.hash
                }
            }else if (token && type == 'admin') {
                if(role != 0 && role !=  2){
                    return {name: "accueil"}
                }
            }
        }
    },
    {
        path: "/ValiderAdmin",
        name: "ValiderAdmin",
        component: () => import(/* webPackChunkName: "ValiderAdmin" */"@/pages/ValiderAdmin"),
        beforeEnter(to, from){
            let token = localStorage.getItem("user");
            let type = localStorage.getItem("type");
            var role = localStorage.getItem('adminRole')
            if(!token || type != 'admin'){
                return {
                    path: '/login/admin',
                    query: to.query,
                    hash: to.hash
                }
            }else if (token && type == 'admin') {
                if(role != 0 && role !=  0){
                    return {name: "accueil"}
                }
            }
        }
    },
    {
        path: '/events',
        name: 'events',
        component: () => import(/* webPackChunkName: "ValiderAdmin" */"@/pages/eventadmin"),
        beforeEnter(to, from){
            let token = localStorage.getItem("user");
            let type = localStorage.getItem("type");
            var role = localStorage.getItem('adminRole')
            if(!token || type != 'admin'){
                return {
                    path: '/login/admin',
                    query: to.query,
                    hash: to.hash
                }
            }else if (token && type == 'admin') {
                if(role != 0 && role !=  3){
                    return {name: "accueil"}
                }
            }
        }
    },
    {
        path: '/archiveEvent',
        name: 'archiveEvent',
        component: () => import(/* webPackChunkName: "ValiderAdmin" */"@/pages/archiveEvent"),
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes, 
})
export default router;