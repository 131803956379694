import Vuex from "vuex"
import cryptage from "@/cryptage/index"

import categorieDataService from "@/services/categorieDataService"
import sousCatDataService from "@/services/sousCatDataService"
import livreDataService from "@/services/livreDataService"
import adminDataService from "@/services/adminDataService"
import userDataService from "@/services/userDataService"
import reservationDataService from "@/services/reservationDataService"
import emprunteDataService from "@/services/emprunteDataService"
import eventsDataService from "@/services/eventsDataService"
import moment from 'moment'

export default new Vuex.Store({
    state: {
        categories: [],
        sousCategories: [],
        livres: [],
        user: [],
        admins: [],
        reservations: [],
        emprunts: [],
        events: []
    },


    getters: {  
        getNouvellesCategories(state, getters){
            return state.categories.filter(element => { element.NumCat > 900})
        }
    },


    actions: {
        fetchAllCategories(context){
            context.state.categories = []
            return categorieDataService.listeToutCategorie()
                .then(res=>{
                    var categories = res.data
                    context.commit('setCategories', categories)
                })
                .catch(err=>{
                    alert('حدث خطأ')
                })      
        },

        createCategorie(context, item){
            return categorieDataService.createCat(item)
                .then(res=>{
                    var tmp = res.data
                    context.commit('pushCategories', tmp)
                })
                .catch(err=>{
                    alert('حدث خطأ')
                })
        },

        deleteCategorie(context, data){
            return categorieDataService.deleteCategorie(data)
                .then(res=>{
                    var tmp = res.data
                    console.log(tmp)
                    if (tmp.delete) {
                        context.commit('removeCategorie', data)
                    }else{
                        alert('لا يمكن حذف هذا التصنيف لاحتوائه على تصنيفات فرعية.')
                    }
                    
                })
                .catch(err=>{
                    alert('حدث خطأ')
                })
        },

        fetchAllSousCat(context){
            context.state.sousCategories = []
            return sousCatDataService.getAllSousCat()
                .then(res=>{
                    var tmp = res.data
                    context.commit('ajouterSousCat', tmp)
                })
                .catch(err=>{
                    alert('حدث خطأ')
                })
        },
        
        fetchSousCatById(context, idCat){
            context.state.sousCategories = []
            return categorieDataService.getAllSousCat(idCat)
                .then(res=>{
                    var tmp = res.data
                    context.commit('setSousCat', tmp)
                })
                .catch(err=>{
                    alert('حدث خطأ')
                })
        },

        createSousCat(context,data){
            var tmp = {
                NumSCat: data.NumSCat,
                NameSCat: data.NameSCat
            }
            return sousCatDataService.createSouCat(data.numCat, tmp)
                .then((res)=>{
                    var tmp = res.data
                    console.log(tmp)
                    context.commit('pushSousCat', tmp)
                })
                .catch(err=>{
                    alert('حدث خطأ')
                })
        }, 

        supprimerSousCat(context, data){
            return sousCatDataService.deleteSouCat(data.idCat, data.idSCat)
                .then(res=>{
                    var tmp = res.data
                    if (tmp.delete) {
                        context.commit('removeSousCat', data.idSCat)
                    }else{
                        alert('لا يمكن حذفه لانه يحتوي على كتب ')
                    }
                })
                .catch(err=>{
                    alert('حدث خطأ')
                })
        },

        fetchLivreBySousCat(context, id){
            context.state.livres = []
            return livreDataService.listeLivreSouCat(id.idCat, id.idScat)
                .then(res=>{
                    var tmp = res.data
                    context.commit('setLivres', tmp)
                })
        },
         
        createLivre(context, data){
            var dataCopie = {...data}
            dataCopie.Titlebook = cryptage.decrtpter(dataCopie.Titlebook)
            dataCopie.authorbook = cryptage.decrtpter(dataCopie.authorbook)
            dataCopie.numScat = dataCopie.numScat.NameSCat
            dataCopie.NumCat = dataCopie.numCat.NameCat

            data.numScat = data.numScat.id
            delete data['numCat']

            return livreDataService.create(data)
                .then((res)=>{
                    var tmp = res.data;
                    
                    tmp.Titlebook = cryptage.decrtpter(tmp.Titlebook)
                    tmp.authorbook = cryptage.decrtpter(tmp.authorbook)
                    tmp.numScat = cryptage.decrtpter(tmp.numScat.NameSCat)
                    console.log(tmp)
                    context.commit('pushLivre', tmp)
                })
                .catch(err=>{
                    alert('حدث خطأ')
                })
        }, 

        searchLivre(context, data){
            return livreDataService.searchLivre(data)
                .then((res)=>{
                    var tmp = res.data
                    context.commit('setLivres', tmp)
                })
                .catch(err=>{
                    alert('حدث خطأ')
                })
        },

        getNewLivre(context){
            context.state.livres = []
            return livreDataService.listeNouveauLivre()
                .then((res)=>{
                    var tmp = res.data
                    context.commit('setLivres', tmp)
                })
                .catch(err=>[
                    alert('حدث خطأ')
                ])
        },
              
        getAllUsers(context){
            context.state.user = []
            return adminDataService.getAllUsers()
                .then((res)=>{
                    var tmp = res.data
                    context.commit('setUsers', tmp)
                })
                .catch(err=>{
                    alert('حدث خطأ')
                })
        },

        validerUser(context, data){
            return adminDataService.validerUser(data.idUser, data.image)
                .then((res)=>{
                    console.log(res.data)
                })
                .catch(err=>{
                    alert('حدث خطأ')
                })
        },

        reserverLivre(context, data){
            return userDataService.reserverLivre(data)
                .catch(err=>{
                    alert('حدث خطأ')
                })
        },

        listeReservationUser(context, idUser){
            
            return userDataService.listeLivrereserve(idUser)
                .then((res)=>{
                    context.state.livres = []
                    var tmp =  res.data
                    context.commit('setLivres', tmp)
                })
                .catch(err=>{
                    alert('حدث خطأ')
                })
        },

        listeToutAdmins(context){
            context.state.admins = []
            return adminDataService.getAllAdmins()
                .then((res)=>{
                    var tmp = res.data
                    context.commit('setAdmins', tmp)
                })
        },

        listeTousReservationsAdmin(context){
            context.state.reservations = []
            return reservationDataService.getAllReservation()
                .then((res)=>{
                    var tmp = res.data
                    context.commit('setReservations', tmp)
                })
                .catch(err=>{
                    alert('حدث خطأ')
                })
        },

        createUserFromAdmin(context, data){
            data.store.numInscription = '/'
            data.store.valide = 'nouveau'
            return adminDataService.creerUser(data.db)
                .then((res)=>{
                    var tmp = res.data
                    if(tmp.creer != false){
                        context.commit('pushUser', data.store)
                    }
                })
        },

        getAllEmprunt(context){
            context.state.emprunts = []
            return emprunteDataService.listeTousEmprunt()
                .then((res)=>{
                    var tmp = res.data
                    context.commit('setEmprunts', tmp)
                })
        },
        
        returnEmprunt(context, data){
            return emprunteDataService.retourEmprunt(data)
                .then((res)=>{
                    var tmp = res.data
                    if (tmp.retour == true) {
                        context.commit('removeEmprunt', data)
                    }else{
                        alert('لا يمكنك إعادة هذا الكتاب')
                    }
                })
        },

        supprimerLivre(context, data){
            return livreDataService.deleteLivre(data)
                .then((res)=>{
                    var tmp =  res.data
                    if (tmp.delete) {
                        context.commit('removeLivre', data)
                        return('true')
                    }else{
                        alert('حدث خطأ')
                    }
                })
        },

        majLivre(context, data){
            return livreDataService.updateLivre(data)
                .then((res)=>{
                    var tmp = res.data.update
                    tmp.numScat = cryptage.decrtpter(tmp.numScat.NameSCat)
                    context.commit('updateLivre', tmp)
                })
        },

        creerEvent(context, data){
            return eventsDataService.create(data)
                .then((res)=>{
                    var tmp =  res.data
                    console.log(tmp)
                })
        },


        listeEvent(context){
            return eventsDataService.listeToutEvent()
                .then((res)=>{
                    var tmp = res.data
                    tmp.forEach(element => {
                        element.dateDebut = moment(element.dateDebut).format('YYYY-MM-DD')
                        element.dateFin = moment(element.dateFin).format('YYYY-MM-DD')
                    });
                    context.commit('setEvent', tmp)
                })
        },

        supprimerEvent(context, data){
            return eventsDataService.deleteEvent(data)
            .then((res) => {
                var tmp = res.data
                console.log(tmp)
            })
        }
    },


    mutations: {
        setCategories(state, categories){
            state.categories = categories;
        },
        pushCategories(state, categorie){
            state.categories.push(categorie)
        },
        removeCategorie(state, categorie){
            for (let i = 0; i < state.categories.length; i++) { 
                if(state.categories[i].id == categorie){
                    state.categories.splice(i,1) 
                }
            }
        },
        ajouterSousCat(state, listeSousCat){
            state.sousCategories = listeSousCat
        },
        pushSousCat(state, data){
            /*var id = data.numCat
            data.numCat = {
                NameCat: "",
                NumCat: ""
            }
            state.categories.forEach(element => {
                if (element.id == id) {
                    data.numCat.NameCat = element.NameCat
                    data.numCat.NumCat = element.NumCat
                }
            });*/
            if (data.numCat) {
                if (isFinite(data.numCat.NameCat)) {
                    data.numCat = cryptage.decrtpter(data.numCat.NameCat)
                }else{
                    data.numCat = data.numCat.NameCat
                }
            }else{
                data.numCat = '/'
            }
            console.log(data)
            state.sousCategories.push(data)
        }, 
        removeSousCat(state, sousCategories){
            for (let i = 0; i < state.sousCategories.length; i++) { 
                if(state.sousCategories[i].id == sousCategories){
                    state.sousCategories.splice(i,1) 
                }
            }
        },
        setSousCat(state, data){
            state.sousCategories = data
        },
        setLivres(state, data){
            state.livres = data
        },
        pushLivre(state, data){
            state.livres.push(data)
        },
        setUsers(state, data){
            state.user = data
        },
        pushUser(state, data){
            state.user.push(data)
        },
        setAdmins(state, data){
            state.admins = data
        },
        setReservations(state, data){
            state.reservations = data
        },
        setEmprunts(state, data){
            state.emprunts = data
        },
        removeEmprunt(state, data){
            for (let i = 0; i < state.emprunts.length; i++) {
                if (state.emprunts[i].id == data.id) {
                    state.emprunts.splice(i,1) 
                }
            }
        },
        removeLivre(state, data){
            for (let i = 0; i < state.livres.length; i++) {
                if (state.livres[i].id == data) {
                    state.livres.splice(i,1) 
                }
            }
        },
        updateLivre(state, data){
            for (let i = 0; i < state.livres.length; i++) {
                if (state.livres[i].id == data.id) {
                    state.livres[i] = data
                }
            }
            
        },
        pushEvent(state, data){
            state.events.push(data)
        },
        setEvent(state, data){
            state.events = data
        },
        removeEvent(state, data){
            for (let i = 0; i < state.events.length; i++) {
                if (state.events[i].id == data.id) {
                    state.events[i] = data
                }
            }
        }
    }
})