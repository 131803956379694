class fakeArab {
    crypter(a) {
        var t = '';
        var x
        var y
        for (let i = 0; i < a.length; i++) {
            x = a[i]
            y = x.charCodeAt(0)
            for (let j = y.toString().length; j < 4; j++) {
                y = '0' + y
            }
            t = t + y
        }
        return t
    }

    decrtpter(t) {
        var q = ''
        var k
        var s
        for (let i = 0; i < t.length; i = i + 4) {
            k = ''
            for (let j = i; j < i + 4; j++) {
                k = k + t[j]
            }
            s = String.fromCharCode(k)
            q = q + s
        }
        return q;
    }
}

export default new fakeArab()