<template>
  <div>

  
    <div class="overlay"></div>
    <div id="carouselExampleIndicators" class="carousel vert slide" data-ride="carousel">
        <div class="carousel-inner">
            <div class="carousel-item active">
              <img class="d-block w-100" src="@/assets/img/pexels-min-an-694740.jpg" alt="First slide">
            </div>
            <div class="carousel-item">
              <img class="d-block w-100" src="@/assets/img/pexels-artem-malushenko-2249063.jpg" alt="Second slide">
            </div>
            <div class="carousel-item">
              <img class="d-block w-100" src="@/assets/img/pexels-wendy-van-zyl-10506690.jpg" alt="Third slide">
            </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
          <span class=" " aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
          <span class=" " aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
    </div>
  </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
.overlay {
  position: absolute;
  top: 27vh;
  left: 0;
  height: 73vh;
  width: 100%;
  background-color: black;
  opacity: 0.3;
  z-index: 1;
}
@media (max-width: 770px){
  .overlay {
 display:none;
}
 img{
  height: 500px;
 }
}
  
.vert.carousel{
    height: 74vh;
    overflow: hidden;
    
}   
.vert .carousel-item-next.carousel-item-left,
.vert .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.vert .carousel-item-next,
.vert .active.carousel-item-right {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100% 0);
}

.vert .carousel-item-prev,
.vert .active.carousel-item-left {
    -webkit-transform: translate3d(0,-100%, 0);
    transform: translate3d(0,-100%, 0);
}
  
</style>