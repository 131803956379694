<template>
  <TheNavigation/>
</template>

<script>
 /* eslint-disable */

import TheNavigation from "@/components/TheNavigation"
export default {
  components: {TheNavigation},
  data () {

  }
}
</script>

<style lang="scss">


/*----visible invisible---*/
.visible{
    display: flex;
}
.in-visible{
    display: none;
}

/*-------------------*/

/*-----home link-----*/
.home-link{
    position: absolute;
    top: 35px;
    left: 35px;
    font-size: 2rem;
    transition: all 0.1s;
    color: black;
    cursor: pointer;
    &:hover{
        font-weight: bold;

    }
}
.seven h1 {
    text-align: center;
    font-size:40px; font-weight:300; color:#000; letter-spacing:1px;
    text-transform: uppercase;
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-template-rows: 27px 0;
    grid-gap: 20px;
    align-items: center;
    font-family: 'Reem Kufi', sans-serif; 
 }

.seven h1:after,.seven h1:before {
    content: " ";
    display: block;
    border-bottom: 3px solid #0D7377;
    border-top: 3px solid #0D7344;
    height: 8px;
      
     
}  
/*----------*/
::-webkit-scrollbar {
  width: 13px;
  background: white;
}
::-webkit-scrollbar-thumb {
  background: #0D7377;
  border-radius: 3px;
}
::-webkit-scrollbar-track {
  background: white;
  border-radius: 0;
}
</style>
