import http from "@/http-common"

class eventsDataService {

    listeToutEvent() {
        return http.get("/events/")
    }

    lastEvent() {
        return http.get("/events/lastOne/")
    }

    eventsByID(id) {
        return http.get(`/events/${id}/`)
    }

    //------- ADMIN -----------------
    create(data) {
        return http.post(`/events/`, data)
    }

    deleteEvent(data){
        //data hiya id 
        console.log(data)
        return http.delete(`/events/${data}/`)
    }


}

export default new eventsDataService();