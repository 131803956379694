<template>  
<div>


  <div class="footer-copyright text-center py-1 " style="background-color: #0D7377; "></div>
    <footer class="page-footer font-small teal  pt-2" style="background-color: #212121;">
      <div class="container-fluid text-center text-md-left">
        <div class="row">
          <div class="col-md-5 mt-md-0 mt-1  mx-auto">
            <h5 class="adnenef mt-3  text-center" style=" color: #14FFEC">المكتـبـة </h5>
            <p class="adnenep  text-center " style=" color: rgb(126,124,123);text-align: justify; ">تقع المكتبة الرئيسية للمطالعة العمومية لولاية تبسة بحي أول نوفمبر ، و هي عبارة عن مكتبة جوارية، المبنى تم انجازه من طرف بلدية تبسة، و هو مكون من ثلاثة طوابق، يحتوى الطابق الارضي على: مكتب استقبال، قاعة مطالعة ونشااطات للأطفال. قسم معالجة الرصيد الوثائقي، فرع الإدارة و الوسائل، مخزن للعتاد.
أما الطابق الاول: يشمل قسم معالجة الرصيد الوثائقي و مخزن كتب الإعارة و قاعة مخصصة للمطالعة للكبار تتسع لخمسين مقعد ،في حين يشمل الطابق الثاني على مكتب التنشيط الثقافي يقابله مكتب المستخدمين ويقابله مكتب الأمانة  ومكتب الميزانية والمحاسبة ومكتب المدير.
</p>
          </div>
          <hr class="clearfix w-100 d-md-none  bg-light">  
          <div class="col-md-2 mb-md-0 col-lg-4 text-center">
            <h5 class="adnenef mt-3 " style=" color: #14FFEC">تواصل معنا </h5>
            <p class="adnenep text-center" style=" color: rgb(126,124,123)"><i class="fa fa-home mr-3 " key="" style=" color: #14FFEC"></i> حي اول نوفمبر تبسة   </p>
            <p class="adnenep text-center" style=" color: rgb(126,124,123)"><i class="fa fa-envelope mr-3" style=" color: #14FFEC"></i> bibliotequetebessa@gmail.com </p>
            <p class="adnenep text-center" style=" color: rgb(126,124,123)"><i class="fa fa-phone mr-3" style=" color: #14FFEC"></i>037 50 84 35</p>
            <p class="adnenep text-center" style=" color: rgb(126,124,123)"><i class="fa fa-print mr-3" style=" color: #14FFEC"></i>037 50 84 36</p>
        </div>
        <hr class="clearfix w-100 d-md-none  bg-light">
        <div class="col-md-4 mb-md-0 col-lg-3 mx-auto text-center ">
          <h5 class=" adnenef mt-3 text-center" style=" color: #14FFEC">مـوقـع المكتـبـة </h5>
          <div id="map-container-google-3" class="z-depth-1-half map-container-3  ">
            <iframe src="https://maps.google.com/maps?q=المكتبة الجوارية للمطالعة&t=k&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0"
            style="border:0" allowfullscreen></iframe>
          </div>
        </div>
      </div>
      
      </div>
      <!-- Copyright -->
      <div class="footer-copyright text-center py-3 " style=" background:  #0D7377;;
    background: linear-gradient(60deg, #0D7377  38%, #000 34%); color: #fff;">© 2022 Copyright:
        <a href="https://mdbootstrap.com/" style="color: #0D7377;"> ThevestSoft.com</a>
      </div>
      <!-- Copyright -->
    </footer>
  <!-- Footer -->  

</div>
</template>
<script>
export default {
    
}
</script>
<style scoped>
 
.footer {
  clear: both;
  height: 220px;
  border: 1px groove #aaaaaa;
  background: rgb(27,27,26);
  color: White;
  padding: 0;
  text-align: center;
  line-height: normal;
  margin: 0;
  bottom: 0px;
  width: 100%;
}
 
 

 
 
.adnenef{
   
  font-family: 'Reem Kufi', sans-serif; 
  font-size: 25px;
} 
.adnenep{  
font-family: 'Mirza', cursive;
font-size: 18px;
text-align: justify;  
} 

</style>